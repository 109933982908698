import React, {useEffect, useRef} from 'react';
import {
    Column,
    ColumnDirective,
    ColumnModel,
    ColumnsDirective,
    DetailDataBoundEventArgs,
    ExcelExport,
    Filter,
    FilterSearchBeginEventArgs,
    FilterSettingsModel,
    GridComponent,
    Inject,
    Page,
    PageSettingsModel,
    QueryCellInfoEventArgs,
    Reorder,
    Resize,
    RowSelectEventArgs,
    Search,
    SelectionSettingsModel,
    Sort,
    SortSettingsModel,
    Toolbar
} from "@syncfusion/ej2-react-grids";
import {ClickEventArgs} from "@syncfusion/ej2-navigations";
import {GridSkeleton} from "../Skeletons/GridSkeleton";
import {CompressorAlert, CompressorAlertSeverity, FaultCategory} from "../../autogenerate/api.generated.clients";
import {getValue} from "@syncfusion/ej2-base";
import './alertsGrid.css';
import {GetFaultCategoryDisplayValue} from "../../utilities/EnumConverter";
import {Button} from "@mui/material";
import {convertDateStringToLocalTime} from "../../utilities/dateHelper";

export interface AlertsGridProps {
    data: CompressorAlert[] | undefined,
    isLoading: boolean,
    hiddenColumns?: string[] | undefined,
    onRowSelected?: (selectedAlert: CompressorAlert) => void,
    onRowDeselected?: (deselectedAlert: CompressorAlert) => void,
    allowMultiSelect?: boolean,
    selectedAlerts?: CompressorAlert[]
}

export function AlertsGrid(props: AlertsGridProps) {
    const {data, isLoading, allowMultiSelect} = props;
    const grid = useRef<GridComponent>(null);
    const gridPageSize = 5000;
    useEffect(() => {
        if (props.hiddenColumns) {
            grid.current?.hideColumns(props.hiddenColumns, "field");
        }
    }, [props.hiddenColumns])

    useEffect(() => {
        if(props.selectedAlerts?.length === 0)
        {
            grid.current?.clearRowSelection();
        }
    }, [props.selectedAlerts]);

    if (isLoading)
        return (<GridSkeleton columns={6} rows={15}/>);

    const toolbarOptions = ["Search", "ExcelExport"];
    const onCreated = () => {
        document?.getElementById(grid.current?.element.id + "_searchbar")?.addEventListener('keyup', (event) => {
            grid.current?.search((event.target as HTMLInputElement).value)
        });
    }
    const filterOptions: FilterSettingsModel = {
        type: 'Excel'
    };
    let sortingOptions: SortSettingsModel = {
        columns: [{field: 'resolutionTimeStamp', direction: 'Descending'}]
    };
    if (props.hiddenColumns) {
        sortingOptions = {
            columns: [{field: 'faultTimeStamp', direction: 'Descending'}]
        }
    }

    const onToolbarClick = async (args: ClickEventArgs) => {
        if (grid && args.item.id === grid.current?.element.id + '_excelexport') {
            grid.current?.excelExport({fileName: "ASC_Alerts.xlsx"});
        }
    }
    const onDataBound = async (args: DetailDataBoundEventArgs) => {
        if (props.hiddenColumns) {
            grid.current?.hideColumns(props.hiddenColumns, "field");
        }
        if (grid) {
            grid.current?.autoFitColumns(['severity', 'frameSerialNumber', 'source', 'faultCategory', 'faultDescription', 'endUserUnitId', 'faultTimeStamp']);
        }
        if(props.selectedAlerts && grid.current) {
            const selectedIndexes = grid.current?.currentViewData
                .map(((data: any, index) => props.selectedAlerts?.some(alert =>
                    alert.faultTimeStamp === data.faultTimeStamp && alert.faultDescription === data.faultDescription
                ) ? index : null))
                .filter(x => x !== null) as number[];
            if (selectedIndexes.length) {
                grid.current?.selectRows(selectedIndexes);
            }
        }
    }
    const customizeCell = (args: QueryCellInfoEventArgs): void => {
        if ((args.column as Column).field == "severity") {
            styleSeverityCell(args);
        }
    }
    const pageOptions: PageSettingsModel = {
        pageSize: gridPageSize
    };
    const severityAccessor = (field: string, data: object, column: ColumnModel) => {
        const severity: CompressorAlertSeverity = getValue("severity", data);
        return CompressorAlertSeverity[severity];
    }
    const categoryAccessor = (field: string, data: object, column: ColumnModel) => {
        const faultCategory: FaultCategory = getValue("faultCategory", data);

        return GetFaultCategoryDisplayValue(faultCategory);
    }
    const actionBegin = (args: FilterSearchBeginEventArgs) => {
        if (args.requestType === "filterchoicerequest" || args.requestType === "filtersearchbegin") {
            args.filterChoiceCount = data?.length ?? 1000;
        }
    }
    const faultTimestampAccessor = (field: string, data: object, column: ColumnModel) => {
        const dateString: string = getValue('faultTimeStamp', data);
        return convertDateStringToLocalTime(dateString);
    }
    const resolutionTimestampAccessor = (field: string, data: object, column: ColumnModel) => {
        const dateString: string = getValue('resolutionTimeStamp', data);
        return convertDateStringToLocalTime(dateString);
    }
    const customSeverityFilter = (props: CompressorAlert) => {
        return CompressorAlertSeverity[props.severity];
    }
    const severityFilterSettings: Object = {
        itemTemplate: customSeverityFilter
    }
    const customCategoryFilter = (props: CompressorAlert) => {
        return FaultCategory[props.faultCategory];
    }
    const categoryFilterSettings: Object = {
        itemTemplate: customCategoryFilter
    }
    const troubleshootingColumnTemplate = (alert: CompressorAlert) =>{
        return (
            <Button data-testid={`link-to-troubleshooting-${alert.faultId}`}
                    size='small'
                    variant='outlined'
                href={`/troubleshooting?faultId=${alert.faultId}`}>
                {'How to fix this'}
            </Button>
        )
    }

    const selectionSettings: SelectionSettingsModel = { mode:  'Row' , type: allowMultiSelect ? 'Multiple' : "Single", enableSimpleMultiRowSelection: allowMultiSelect, persistSelection: true};
    const onRowSelected = (args: RowSelectEventArgs) => {
        if(props.onRowSelected && args.isInteracted )
        {
            if(Array.isArray(args.data))
                args.data.map(row => 
                    props.onRowSelected?(args.data as CompressorAlert) : ''
                );
            else
                props.onRowSelected(args.data as CompressorAlert)
        }
    }

    const onRowDeSelected = (args: RowSelectEventArgs) => {
        if(props.onRowDeselected && args.isInteracted )
        {
            if(Array.isArray(args.data))
                args.data.map(row =>
                    props.onRowDeselected?(args.data as CompressorAlert) : ''
                );
            else
                props.onRowDeselected(args.data as CompressorAlert)
        }
    }
    
    
    return (
        <GridComponent height={'100%'} id="alertsGrid" ref={grid} enableStickyHeader={true} dataSource={data}
                       toolbar={toolbarOptions} queryCellInfo={customizeCell}
                       created={onCreated} allowExcelExport={true} allowFiltering={true} allowReordering
                       allowResizing={true} filterSettings={filterOptions} toolbarClick={onToolbarClick}
                       dataBound={onDataBound} rowSelected={onRowSelected} rowDeselected={onRowDeSelected}
                       allowSorting={true} allowMultiSorting={true} loadingIndicator={{indicatorType: 'Shimmer'}}
                       allowPaging pageSettings={pageOptions} sortSettings={sortingOptions}
                       actionBegin={actionBegin} selectionSettings={selectionSettings}>
            <ColumnsDirective>
                <ColumnDirective field="severity" headerText={"Severity"} valueAccessor={severityAccessor}
                                 filter={severityFilterSettings}/>
                <ColumnDirective field="endUserUnitId" headerText={"End User Unit ID"}/>
                <ColumnDirective field="frameSerialNumber" width="auto" headerText={"Frame"}/>
                <ColumnDirective field="source" width="auto" headerText={"Source"}/>
                <ColumnDirective field="faultCategory" width="auto" headerText={"Category"}
                                 valueAccessor={categoryAccessor} filter={categoryFilterSettings}/>
                <ColumnDirective field="faultDescription" width="auto" headerText={"Description"}/>
                <ColumnDirective field="faultTimeStamp" width="auto" headerText={"Alert Date/Time"}
                                 valueAccessor={faultTimestampAccessor}/>
                <ColumnDirective field="resolutionTimeStamp" width="auto" headerText={"Resolution Date/Time"}
                                 valueAccessor={resolutionTimestampAccessor}/>
                <ColumnDirective field="troubleshootingLink" headerText="How To Fix" template={troubleshootingColumnTemplate} allowEditing={false}/>
            </ColumnsDirective>
            <Inject services={[Toolbar, Search, Filter, ExcelExport, Resize, Reorder, Sort, Page]}/>
        </GridComponent>
    );
}

const styleSeverityCell = (args: QueryCellInfoEventArgs): void => {
    if (args.data && args.cell) {
        const severity = getValue("severity", args.data);
        let className: string = "";
        switch (severity) {
            case CompressorAlertSeverity.Shutdown:
                className = "severity-cell-shutdown";
                break;
            case CompressorAlertSeverity.Warning:
                className = "severity-cell-warning";
                break;
            case CompressorAlertSeverity.NA:
                className = "severity-cell-na";
                break;
        }
        if (className !== "") {
            args.cell?.classList.add(className)
        }
        args.cell?.classList.add('severity-cell')
    }
}
