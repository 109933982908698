import React, {useState} from 'react';
import {useResolvedFleetAlerts} from "../../../../hooks/FleetDashboard/useFleetAlerts";
import {getOneDayAgo} from "../../../../utilities/dateHelper";
import {ChangedEventArgs, DateTimePickerComponent} from "@syncfusion/ej2-react-calendars";
import {AlertsGrid} from "../../../../components/AlertsGrid/AlertsGrid";
import './fleetResolvedAlertsTab.css';
import {ButtonComponent} from "@syncfusion/ej2-react-buttons";

export function FleetResolvedAlertsTabContent() {
    const [resolutionStartDate, setResolutionStartDate] = useState<Date>(getOneDayAgo());
    const [resolutionEndDate, setResolutionEndDate] = useState<Date>(new Date());
    const [isQueryEnabled, setIsQueryEnabled] = useState(false);
    const [dateLabelText, setDateLabelText] = useState("Get alerts resolved between ");
    const {data, isLoading, error} = useResolvedFleetAlerts({
        alertResolutionStartDate: resolutionStartDate,
        alertResolutionEndDate: resolutionEndDate
    }, isQueryEnabled);

    const onResolutionStartChanged = (args: ChangedEventArgs) => {
        if (args.value) {
            setResolutionStartDate(args.value)
        }
    }
    const onResolutionEndChanged = (args: ChangedEventArgs) => {
        if (args.value) {
            setResolutionEndDate(args.value)
        }
    }

    const onGetAlerts = () => {
        setIsQueryEnabled(true);
        setDateLabelText("Showing alerts resolved between ");
    }

    return (
        <div className="fleet-resolved-alerts-tab-container">
            <div className="fleet-resolved-alerts-search-form-container">
                <span>{dateLabelText}</span>
                <DateTimePickerComponent width="200px" showClearButton={false} allowEdit={false} openOnFocus={true}
                                         value={resolutionStartDate} change={onResolutionStartChanged}/>
                <span> and </span>
                <DateTimePickerComponent width="200px" showClearButton={false} allowEdit={false} openOnFocus={true}
                                         value={resolutionEndDate} change={onResolutionEndChanged}/>
            </div>

            {!isQueryEnabled ?
                <ButtonComponent cssClass="get-alerts-button" isPrimary onClick={onGetAlerts}>Get
                    Alerts</ButtonComponent>
                :
                <AlertsGrid data={data} isLoading={isLoading}/>
            }
        </div>
    );
}