import './fleetDataQuality.scss';
import {useFleetDataQualityReport} from "../../../hooks/Reports/useFleetDataQualityReport";
import React, {useEffect, useRef, useState} from "react";
import {
    ColumnDirective,
    ColumnsDirective, Edit, Filter,
    FilterSettingsModel,
    GridComponent, Inject, PdfExport, Reorder, Resize, Sort,
    TextWrapSettingsModel
} from "@syncfusion/ej2-react-grids";
import {GridSkeleton} from "../../../components/Skeletons/GridSkeleton";
import {DataQualityReport, Organization} from "../../../autogenerate/api.generated.clients";
import {VariableStatusIcon} from "../../CompressorLanding/DataQuality/DataQualityReport/VariableStatusIcon";
import {Button} from "@mui/material";
import {utils, WorkBook, writeFile} from "xlsx";
import OrganizationsAutoComplete from "../../../components/OrganizationsAutoComplete/OrganizationsAutoComplete";
import {useUserParentOrganizations} from "../../../hooks/Organization/useUserParentOrganizations";
import {useGetIsArielEmployee} from "../../../hooks/Organization/useGetIsArielEmployee";

function FleetDataQuality() {
    const [subOrg, setSubOrg] = useState<Organization | null>(null);
    const fleetDataQualityData = useFleetDataQualityReport(subOrg?.externalId);
    const parentOrganizations = useUserParentOrganizations().data;
    const isArielEmployee = useGetIsArielEmployee().data;
    
    const grid = useRef<GridComponent>(null);
    const filterOptions: FilterSettingsModel = {type: 'Excel'};
    const textWrapSettings: TextWrapSettingsModel = {wrapMode: "Header"}

    if (fleetDataQualityData.isLoading)
        return (
            <div style={{display: 'flex', justifyContent: 'center'}}>
                <div className={'e-card'} style={{maxWidth: '50%', minWidth: '65em', backgroundColor: 'white', margin: '1em'}}>
                    <GridSkeleton columns={6} rows={20}/>
                </div>
            </div>
        );
    
    const sortedData = fleetDataQualityData.data?.sort((a, b) => {
        if(a.missingVariableCount > 0 || b.missingVariableCount > 0)
            return b.missingVariableCount - a.missingVariableCount;
        else if (a.extraVariableCount > 0 || b.extraVariableCount > 0)
            return b.extraVariableCount - a.extraVariableCount;
        return 0;
    });

    const dataBound = () => {
        if (grid) {
            grid.current?.autoFitColumns(['frameSerialNumber', 'missingVariableCount', 'extraVariableCount', 'goodVariableCount']);
        }
    };

    const variableStatusTemplate = (props: DataQualityReport) => {
        const status = props.missingVariableCount > 0 ? "missing"
            : props.extraVariableCount > 0 ? "unnecessary"
                : "good"
        return (<div className='valve-status-icon'>
            <VariableStatusIcon status={status}/>
        </div>);
    }

    const exportToExcelTemplate = (props: DataQualityReport) => {
        return (
            <Button data-testid={`export-frame-${props.frameSerialNumber}`}
                    size='small'
                    variant='outlined'
                    onClick={onExportSingleClicked(props.frameSerialNumber)}
            >
                {'Export'}
            </Button>
        );
    }

    function addWorksheetForCompressor(frameSerialNumber: string, workbook: WorkBook) {
        let sheetName = frameSerialNumber;
        const sheetExists = workbook.SheetNames.includes(sheetName);

        if(sheetExists) {
            let counter = 0;
            do {
                sheetName = `${frameSerialNumber}_${++counter}`;
            } while(workbook.SheetNames.includes(sheetName));
        }

        const worksheet = utils.json_to_sheet(fleetDataQualityData.data?.find(x => x.frameSerialNumber === frameSerialNumber)?.variableComplianceStatuses ?? []);
        utils.book_append_sheet(workbook, worksheet, sheetName);
    }

    const onExportSingleClicked = (frameSerialNumber: string) => async () => {
        const workbook = utils.book_new();
        addWorksheetForCompressor(frameSerialNumber, workbook);

        const today = new Date();
        const formattedDate = today.toISOString().split('T')[0];

        writeFile(workbook, `${frameSerialNumber}_DataQualityReport_${formattedDate}.xlsx`);
    }
    const onExportFleetClicked = async () => {
        let workbook = utils.book_new();

        fleetDataQualityData.data?.forEach(x => addWorksheetForCompressor(x.frameSerialNumber, workbook));

        const today = new Date();
        const formattedDate = today.toISOString().split('T')[0];

        writeFile(workbook, `Fleet_DataQualityReport_${formattedDate}.xlsx`);
    }

    return (
        <div className="fleet-data-quality-report-container" >
            {
                isArielEmployee ?
                    <div className="auto-complete-container">
                        <div className="auto-complete-width">
                            <OrganizationsAutoComplete
                                updateSelectedOrg={(selectedOrg: Organization) => setSubOrg(selectedOrg)}
                                parentOrganizations={parentOrganizations} selectedOrganization={subOrg}
                            />
                        </div>
                    </div>
                :
                    ""
            }
            <div className={`e-card`} style={{alignSelf: 'center', width: 'auto'}}>
                <div className={'table-header'}>
                    <span className="e-card-header">Data Quality Report</span>
                    <Button data-testid={`export-all-data-quality`}
                            size='small'
                            style={{alignSelf: 'center'}}
                            onClick={onExportFleetClicked}
                            variant='outlined'>
                        Export All To Excel
                    </Button>
                </div>
                <GridComponent className="leak-index-grid"
                               width="auto" height="100%"
                               ref={grid}
                               enableStickyHeader
                               dataSource={sortedData}
                               allowResizing
                               allowSorting
                               allowFiltering
                               statelessTemplates={['directiveTemplates']}
                               allowTextWrap
                               textWrapSettings={textWrapSettings}
                               filterSettings={filterOptions} dataBound={dataBound} allowReordering
                               loadingIndicator={{indicatorType: 'Shimmer'}}>
                    <ColumnsDirective>
                        <ColumnDirective template={variableStatusTemplate} width="75px" textAlign="Left"
                                         headerText=""/>
                        <ColumnDirective field="endUserUnitId" textAlign="Left"  headerText="End User Unit ID"/>
                        <ColumnDirective field="frameSerialNumber" textAlign="Left" headerText="Frame SN"/>
                        <ColumnDirective field="missingVariableCount" width="auto" textAlign="Right"
                                         headerText="Missing"/>
                        <ColumnDirective field="extraVariableCount" textAlign="Right" headerText="Unnecessary"/>
                        <ColumnDirective field="correctVariableCount" textAlign="Right" headerText="Correct"/>
                        <ColumnDirective template={exportToExcelTemplate} width="150px" textAlign="Center"
                                         headerText=""/>
                    </ColumnsDirective>
                    <Inject services={[Resize, Sort, Reorder, PdfExport, Edit, Filter]}/>
                </GridComponent>
            </div>
        </div>
    );
}

export default FleetDataQuality;