import {Client} from "../../autogenerate/api.generated.clients";
import {useQuery} from "react-query";

interface UseDataQualitySummaryProps {
    frameSerialNumber: string;
}

function useDataQualitySummary({frameSerialNumber}: UseDataQualitySummaryProps) {
    return useQuery(['dataQualitySummary', frameSerialNumber], async () =>  new Client().report_GetDataQualitySummaryForFrame(frameSerialNumber));
}

export default useDataQualitySummary;