import './compressorFilterView.scss'
import {useEffect, useState} from "react";
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Button,
    Checkbox,
    CircularProgress,
    Divider,
    FormControlLabel,
    FormGroup,
    IconButton,
    Stack,
    ToggleButton,
    ToggleButtonGroup,
    Typography
} from "@mui/material";
import ExpandMore from "@mui/icons-material/ExpandMore";
import ChevronRight from "@mui/icons-material/ChevronRight";
import CloseIcon from '@mui/icons-material/Close';
import {
    useOrganizationHierarchyForLoggedInUser
} from "../../hooks/Organization/useOrganizationHierarchyForLoggedInUser";
import {
    CompressorStatusCode,
    HierarchyItem,
    HierarchyItemType,
    OrganizationHierarchyItem
} from "../../autogenerate/api.generated.clients";
import {TreeItem, TreeView} from "@mui/x-tree-view";

export type CompressorFilterViewProps = JSX.IntrinsicElements["div"] & {
    onFavoriteFilterChanged?: (hasFavoriteFilterApplied: boolean) => void;
    onSelectedStatusCodeChanged?: (selectedStatusCodes: CompressorStatusCode[]) => void;
    onSelectedSubOrgsChanged?: (selectedOrgs: OrganizationHierarchyItem[]) => void;
    initialSubOrgSelection?: OrganizationHierarchyItem[],
    initialIsFavoriteSelected?: boolean,
    initialSelectedStatuses?: CompressorStatusCode[],
    filterCount: number,
    onCloseFiltersClick: Function,
    onClearFiltersClick: Function
}

function CompressorFilterView(props: CompressorFilterViewProps) {
    const {
        onFavoriteFilterChanged,
        onSelectedStatusCodeChanged,
        onSelectedSubOrgsChanged,
        initialSelectedStatuses,
        initialIsFavoriteSelected,
        initialSubOrgSelection,
        filterCount,
        onCloseFiltersClick,
        onClearFiltersClick,
        children,
        ...rest
    } = props;
    const [favoriteToggleValue, setFavoriteToggleValue] = useState(initialIsFavoriteSelected ? 'favorites' : 'all');
    const orgHierarchy = useOrganizationHierarchyForLoggedInUser()
    const [selectedStatusCodes, setSelectedStatusCodes] = useState(initialSelectedStatuses ?? [] as CompressorStatusCode[]);
    const [selectedSuborgs, setSelectedSuborgs] = useState(initialSubOrgSelection ?? [] as OrganizationHierarchyItem[]);
    useEffect(() => {
        if (onSelectedStatusCodeChanged) {
            onSelectedStatusCodeChanged(selectedStatusCodes);
        }
    }, [selectedStatusCodes]);
    useEffect(() => {
        if (onSelectedSubOrgsChanged) {
            onSelectedSubOrgsChanged(selectedSuborgs);
        }
    }, [selectedSuborgs]);
    const handleFavoriteToggle = (event: React.MouseEvent<HTMLElement>, newValue: string,) => {
        if (newValue) {
            setFavoriteToggleValue(newValue);
            if (onFavoriteFilterChanged) {
                onFavoriteFilterChanged(newValue === "favorites");
            }
        }
    };
    const getChildSuborgs = (suborg: OrganizationHierarchyItem): OrganizationHierarchyItem[] => {
        return [suborg, ...(suborg.children.filter(c => c.type === HierarchyItemType.Org).flatMap(c => getChildSuborgs(c as OrganizationHierarchyItem)))];
    }
    const onSuborgCheckboxClicked = (suborg: OrganizationHierarchyItem) => {
        const childSuborgs = getChildSuborgs(suborg);
        if (selectedSuborgs.includes(suborg)) {
            setSelectedSuborgs(selectedSuborgs.filter(s => !childSuborgs.includes(s)))
        } else {
            setSelectedSuborgs([...selectedSuborgs, ...childSuborgs]);
        }
    }
    const getOrgTreeView = (hierarchyItem: any) => {
        return (
            <TreeItem key={hierarchyItem.id} itemId={hierarchyItem.id} label={
                <div>
                    <Checkbox onClick={e => {
                        e.stopPropagation();
                        onSuborgCheckboxClicked(hierarchyItem as OrganizationHierarchyItem);
                    }}
                              checked={selectedSuborgs.includes(hierarchyItem)}
                    />
                    {hierarchyItem.name}
                </div>
            }>
                {hierarchyItem?.children?.filter((item: any) => item.type === HierarchyItemType.Org)
                    .sort((a : HierarchyItem, b : HierarchyItem) => {
                        let textA = a.name.toLowerCase();
                        let textB = b.name.toLowerCase();
                        return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
                    })
                    .map((item: any, index: number) =>
                    getOrgTreeView(item)
                )}
            </TreeItem>
        )
    }
    const onStatusCodeCheckboxChanged = (statusCode: CompressorStatusCode, isChecked
        :
        boolean
    ) => {
        if (isChecked && !selectedStatusCodes.includes(statusCode)) {
            setSelectedStatusCodes([...selectedStatusCodes, statusCode]);
        } else if (!isChecked) {
            setSelectedStatusCodes(selectedStatusCodes.filter(s => s !== statusCode));
        }
    }

    const getSubOrgCount = (orgs: HierarchyItem[] | undefined): number => {
        if (orgs) {
            return orgs.filter(o => o.type === HierarchyItemType.Org)
                .reduce((total: number, org: any) => {
                    return total + 1 + getSubOrgCount(org.children);
                }, 0);
        }
        return 0;
    }

    const handleClearFiltersClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setFavoriteToggleValue('all');
        setSelectedStatusCodes([] as CompressorStatusCode[]);
        setSelectedSuborgs([] as OrganizationHierarchyItem[]);
        onClearFiltersClick();
    }

    return (
        <div>
            <div {...rest} className='compressor-filter-container'>
                <div className="header-container">
                    <div/>
                    <Typography className='header'>
                        Sort & Filter
                    </Typography>
                    <div className="close-button">
                        <IconButton aria-label="close filters" size="small" onClick={e => {
                            onCloseFiltersClick(e)
                        }}>
                            <CloseIcon fontSize="inherit"/>
                        </IconButton>
                    </div>
                </div>
                <ToggleButtonGroup size='small'
                                   fullWidth
                                   exclusive
                                   color={"primary"}
                                   className='favorite-toggle-buttons'
                                   value={favoriteToggleValue}
                                   onChange={handleFavoriteToggle}
                                   data-testid='favoritesButtonGroup'
                >
                    <ToggleButton value="all">All compressors</ToggleButton>
                    <ToggleButton value='favorites'>Only favorites</ToggleButton>
                </ToggleButtonGroup>
                <Accordion
                    defaultExpanded={true}
                    className='filter-accordion'>
                    <AccordionSummary
                        expandIcon={<ExpandMore/>}
                        aria-controls="status-filter-content"
                        id="status-filter-header"
                    >
                        <div className='filter-accordion-header'>
                            <div className='filter-accordion-title-container'>
                                <Typography>
                                    Compressor status
                                </Typography>
                                <Typography fontSize='small'
                                            sx={{color: selectedStatusCodes.length ? '#0072c6' : '#aaa'}}>
                                    {selectedStatusCodes.length} / 7
                                </Typography>
                            </div>
                            <Typography sx={{color: 'text.secondary'}} fontSize='small'>Only see compressors of a
                                specific
                                status</Typography>
                        </div>
                    </AccordionSummary>
                    <AccordionDetails>
                        <FormGroup className='status-checkbox-form'>
                            <FormControlLabel
                                control={<div className='status-checkbox'>
                                    <Checkbox
                                        checked={selectedStatusCodes.includes(CompressorStatusCode.ShutdownFault)}
                                        onChange={e => onStatusCodeCheckboxChanged(CompressorStatusCode.ShutdownFault, e.target.checked)}/>
                                    <span className='status-color shutdown-fault'/>
                                </div>}
                                label="Alert - Shutdown"/>
                            <FormControlLabel
                                control={<div className='status-checkbox'>
                                    <Checkbox
                                        checked={selectedStatusCodes.includes(CompressorStatusCode.RunningShutdownFault)}
                                        onChange={e => onStatusCodeCheckboxChanged(CompressorStatusCode.RunningShutdownFault, e.target.checked)}/>
                                    <span className='status-color running-shutdown-fault'/></div>}
                                label="Running Shutdown Fault"/>
                            <FormControlLabel
                                control={<div className='status-checkbox'>
                                    <Checkbox
                                        checked={selectedStatusCodes.includes(CompressorStatusCode.ActiveWarning)}
                                        onChange={e => onStatusCodeCheckboxChanged(CompressorStatusCode.ActiveWarning, e.target.checked)}/>
                                    <span className='status-color warning'/></div>}
                                label="Alert - Warning"/>
                            <FormControlLabel
                                control={<div className='status-checkbox'>
                                    <Checkbox
                                        checked={selectedStatusCodes.includes(CompressorStatusCode.OK)}
                                        onChange={e => onStatusCodeCheckboxChanged(CompressorStatusCode.OK, e.target.checked)}/>
                                    <span className='status-color ok'/>
                                </div>}
                                label="OK"/>
                            <FormControlLabel
                                control={<div className='status-checkbox'>
                                    <Checkbox
                                        checked={selectedStatusCodes.includes(CompressorStatusCode.NormalShutdown)}
                                        onChange={e => onStatusCodeCheckboxChanged(CompressorStatusCode.NormalShutdown, e.target.checked)}/>
                                    <span className='status-color normal-shutdown'/>
                                </div>}
                                label="Normal Shutdown"/>
                            <FormControlLabel
                                control={<div className='status-checkbox'>
                                    <Checkbox
                                        checked={selectedStatusCodes.includes(CompressorStatusCode.Disconnected)}
                                        onChange={e => onStatusCodeCheckboxChanged(CompressorStatusCode.Disconnected, e.target.checked)}/>
                                    <span className='status-color disconnected'/>
                                </div>}
                                label="Disconnected"/>
                            <FormControlLabel
                                control={<div className='status-checkbox'>
                                    <Checkbox
                                        checked={selectedStatusCodes.includes(CompressorStatusCode.NonASC)}
                                        onChange={e => onStatusCodeCheckboxChanged(CompressorStatusCode.NonASC, e.target.checked)}/>
                                    <span className='status-color non-asc'/></div>}
                                label="Non-ASC Alert"/>
                            <FormControlLabel
                                control={<div className='status-checkbox'>
                                    <Checkbox
                                        checked={selectedStatusCodes.includes(CompressorStatusCode.Unknown)}
                                        onChange={e => onStatusCodeCheckboxChanged(CompressorStatusCode.Unknown, e.target.checked)}/>
                                    <span className='status-color unknown'/></div>}
                                label="Unknown"/>
                        </FormGroup>
                    </AccordionDetails>
                </Accordion>
                <Divider/>
                <Accordion
                    defaultExpanded={true}
                    className='filter-accordion'>
                    <AccordionSummary
                        expandIcon={<ExpandMore/>}
                        aria-controls="sub-org-filter-content"
                        id="sub-org-filter-header"
                    >
                        <div className='filter-accordion-header'>
                            <div className='filter-accordion-title-container'>
                                <Typography>
                                    Sub-Organizations
                                </Typography>
                                <Typography fontSize='small'
                                            sx={{color: selectedStatusCodes.length ? '#0072c6' : '#aaa'}}>
                                    {selectedSuborgs.length} / {getSubOrgCount(orgHierarchy.data)}
                                </Typography>
                            </div>
                            <Typography sx={{color: 'text.secondary'}} fontSize='small'>Only see compressors from
                                selected
                                organizations</Typography>
                        </div>
                    </AccordionSummary>
                    <AccordionDetails>
                        <TreeView
                            slots={{
                                collapseIcon: ExpandMore,
                                expandIcon: ChevronRight
                            }}>
                            {orgHierarchy.isLoading ?
                                <div className='center-content'>
                                    <CircularProgress/>
                                </div>
                                :
                                orgHierarchy.data?.filter(item => item.type === HierarchyItemType.Org)
                                    .sort((a,b) => {
                                        let textA = a.name.toLowerCase();
                                        let textB = b.name.toLowerCase();
                                        return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
                                    })
                                    .map((item, index) =>
                                    getOrgTreeView(item)
                                )}
                        </TreeView>
                    </AccordionDetails>
                </Accordion>
                {children}
            </div>
            <Divider/>
            <Stack className="confirmation-button-container" direction="row" spacing={2} justifyContent="space-between"
                   alignItems="center">
                <Button variant="outlined" disabled={filterCount <= 0} onClick={e => {
                    handleClearFiltersClick(e)
                }}>Clear All</Button>
            </Stack>
        </div>
    );
}

export default CompressorFilterView;