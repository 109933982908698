import React, {useState} from 'react';
import {
    Box,
    Button,
    TextField,
    Typography,
    MenuItem,
    Select,
    FormControl,
    InputLabel,
    SelectChangeEvent
} from '@mui/material';
import useMlinkFirmwareRevisions from "../../../../hooks/Reports/useMlinkFirmwareRevisions";
import {CompressorMlinkFirmwareRevisionDTO} from "../../../../autogenerate/api.generated.clients";

interface RequestRevisionUpdateFormProps {
    frameSerialNumber: string,
    onCancel: () => void,
    onSubmit: (revisionNumber: string, notes: string) => void,
    currentRevision?: undefined | CompressorMlinkFirmwareRevisionDTO
}

function RequestRevisionUpdateForm(props: RequestRevisionUpdateFormProps) {
    const {frameSerialNumber, onCancel, onSubmit, currentRevision} = props;
    const revisions = useMlinkFirmwareRevisions();
    const [revisionNumber, setRevisionNumber] = useState(currentRevision?.mlinkFirmwareRevision ?? ' ');
    const [notes, setNotes] = useState('');
    
    const filteredRevisions = revisions.data?.filter(revision => revision >= Number(currentRevision?.mlinkFirmwareRevision));

    const handleRevisionChange = (event: SelectChangeEvent<string>) => {
        setRevisionNumber(event.target.value);
    };


    const handleNotesChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setNotes(event.target.value);
    };

    const handleSubmit = () => {
        onSubmit(revisionNumber, notes);
    };

    return (
        <Box sx={{display: 'flex', flexDirection: 'column', gap: 2}}>
            <Typography variant="h6">Request Update</Typography>
            <TextField
                label="Frame Serial Number"
                value={frameSerialNumber}
                disabled={true}
                variant="outlined"
                fullWidth
            />
            <FormControl variant="outlined" fullWidth>
                <InputLabel id="revision-number-label">Requested Revision Number</InputLabel>
                <Select
                    variant={"outlined"}
                    labelId="revision-number-label"
                    value={revisionNumber}
                    onChange={handleRevisionChange}
                    label="Revision Number"
                >
                    {filteredRevisions?.map(revision => (
                        <MenuItem key={revision} value={revision}>{revision}</MenuItem>
                    ))}
                </Select>
            </FormControl>
            <TextField
                label="Notes"
                value={notes}
                onChange={handleNotesChange}
                variant="outlined"
                multiline
                rows={4}
                fullWidth
            />
            <Box sx={{display: 'flex', justifyContent: 'flex-end', gap: 2}}>
                <Button variant="outlined" onClick={onCancel}>Cancel</Button>
                <Button variant="contained" color="primary" onClick={handleSubmit}>Submit Request</Button>
            </Box>
        </Box>
    );
}

export default RequestRevisionUpdateForm;