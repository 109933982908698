import React, {useEffect, useState} from 'react';
import {useCompressorDiagram} from "../../hooks/Compressor/Telemetry/useCompressorDiagram";
import {SkeletonComponent} from "@syncfusion/ej2-react-notifications";
import {ISerialNumberedEquipment, SerialNumberedEquipmentType} from "../../autogenerate/api.generated.clients";
import {Unloader} from "./Unloader/Unloader";
import {Cylinder} from "./Cylinder/Cylinder";
import {Frame} from "./Frame/Frame";
import './compressorDiagram.scss';

export interface CompressorDiagramProps extends React.HTMLProps<HTMLDivElement> {
    frameSerialNumber: string,
    equipmentChanged?: Function,
    selectFrameOnLoad?: boolean,
    isUnloadersDisabled?: boolean,
    isCylindersDisabled?: boolean
}

export function CompressorDiagram(props: CompressorDiagramProps) {
    const {frameSerialNumber, equipmentChanged, isUnloadersDisabled, selectFrameOnLoad, isCylindersDisabled, ...reactProps} = props;
    const {data, isLoading} = useCompressorDiagram({frameSerialNumber});
    const [selectedEquipment, setSelectedEquipment] = useState<ISerialNumberedEquipment>();

    useEffect(() => {
        if (selectFrameOnLoad) {
            const frame: ISerialNumberedEquipment = {
                equipmentType: SerialNumberedEquipmentType.Frame,
                serialNumber: frameSerialNumber
            }
            setSelectedEquipment(frame)
        }
    }, []);

    useEffect(() => {
        if (data && selectedEquipment?.equipmentType === SerialNumberedEquipmentType.Frame) {
            var throwCount = data.length * 2;
            for (let i = 1; i <= throwCount; i++) {
                const cylindersOnThrow = data.flatMap(x =>
                    x.map((y: any) => {
                        if (y && y.equipmentType == SerialNumberedEquipmentType.Cylinder && y.throwNumber === i) {
                            return y;
                        }
                    })
                ).filter(x => x !== undefined);
                if (cylindersOnThrow.length) {
                    setSelectedEquipment(cylindersOnThrow[0]);
                    break;
                }
            }
        }
    }, [data]);

    useEffect(() => {
        if (props.equipmentChanged) {
            props.equipmentChanged(selectedEquipment);
        }
    }, [selectedEquipment])
    if (isLoading || !data) {
        return <SkeletonComponent width="100%" height="100%" style={{minHeight: '30em'}}/>
    }

    const onEquipmentSelected = (equipment: ISerialNumberedEquipment) => {
        setSelectedEquipment(equipment);
    };


    const frameIndex = data[0].findIndex(e => e.equipmentType == SerialNumberedEquipmentType.Frame) ?? 0;
    return (
        <div {...reactProps} className={`compressor-diagram-container ${props.className}`}>
            <div style={{height: '80%', display: 'grid', gridTemplateColumns: `${getGridTemplateColumn(data[0])}`}}>
                {
                    data.map((row: any, innerIndex) => {
                        return (
                            row.map((equipment: any, index: number) => {
                                if (equipment) {
                                    const throwNumber = equipment.throwNumber
                                    switch (equipment.equipmentType) {
                                        case SerialNumberedEquipmentType.Cylinder :
                                            return <Cylinder key={`cylinder-${innerIndex}-${index}`}
                                                             isDisabled={isCylindersDisabled}
                                                             equipment={equipment}
                                                             isEvenThrow={index > frameIndex}
                                                             isSelected={selectedEquipment?.serialNumber === equipment.serialNumber}
                                                             equipmentSelected={onEquipmentSelected}
                                                             style={{
                                                                 position: 'relative',
                                                                 top: getPositionAdjustment(throwNumber, data?.length * 2)
                                                             }}/>
                                        case SerialNumberedEquipmentType.Frame :
                                            return <Frame key={`frame-${innerIndex}-${index}`}
                                                          style={{marginTop: "-10%", height: '120%'}}
                                                          equipment={equipment}
                                                          isDisabled
                                                          isSelected={selectedEquipment?.serialNumber === equipment.serialNumber}
                                            />
                                            break;
                                        case SerialNumberedEquipmentType.Unloader :
                                            return <Unloader key={`unloader-${innerIndex}-${index}`}
                                                             isDisabled={isUnloadersDisabled}
                                                             equipmentSelected={onEquipmentSelected}
                                                             isSelected={selectedEquipment?.serialNumber === equipment.serialNumber}
                                                             equipment={equipment}
                                                             isEvenThrow={index > frameIndex}
                                                             style={{
                                                                 position: 'relative',
                                                                 top: getPositionAdjustment(throwNumber, data?.length * 2)
                                                             }}/>
                                        default :
                                            return <div key={`empty-${innerIndex}-${index}`}>I am nothing</div>
                                    }
                                }
                            }))
                    })
                }
            </div>
        </div>
    );
}

const getPositionAdjustment = (throwNum: number, totalThrows: number): string => {
    switch (totalThrows) {
        case 2:
            return get2ThrowPosition(throwNum);
        case 4:
            return get4ThrowPosition(throwNum);
        case 6:
            return get6ThrowPosition(throwNum);
    }
    return '0';
}

const get2ThrowPosition = (throwNum: number): string => {
    switch (throwNum) {
        case 2:
            return '-18%';
    }
    return '0';
}

const get6ThrowPosition = (throwNum: number): string => {
    switch (throwNum) {
        case 1:
            return '10%';
        case 2:
            return '-10%';
        case 3:
            return '20%';
        case 4:
            return '0';
        case 5:
            return '30%';
        case 6:
            return '10%';
    }

    return '0';
}

const get4ThrowPosition = (throwNum: number): string => {
    switch (throwNum) {
        case 1:
            return '10%';
        case 2:
            return '-12%';
        case 3:
            return '15%';
        case 4:
            return '-6%';
    }
    return '0';
}
export const getGridTemplateColumn = (equipmentRow: ISerialNumberedEquipment[]) => {
    return equipmentRow.map(e => e.equipmentType == SerialNumberedEquipmentType.Frame ? '2fr' : '1fr').join(" ");
}

export default CompressorDiagram;