import {useQuery} from "react-query";
import {Client} from "../../autogenerate/api.generated.clients";

interface UseCurrentMlinkRevisionProps {
    frameSerialNumber: string
}

function useCurrentMlinkRevision({frameSerialNumber}: UseCurrentMlinkRevisionProps) {
    return useQuery(['currentMlinkRevision', frameSerialNumber], async () => {
        const revisions = await new Client().report_GetMlinkFirmwareRevisionsForCompressors([frameSerialNumber]);
        return revisions[0]; // Return only the first element for single frame serial number
    });}

export default useCurrentMlinkRevision;