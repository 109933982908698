import React from 'react';
import './skeletons.scss';
import {SkeletonComponent} from "@syncfusion/ej2-react-notifications";

export interface GridSkeletonProps {
    columns: number,
    rows: number
}

export function GridSkeleton(props: GridSkeletonProps) {
    const {columns, rows} = props;

    return (
        <div data-testid="grid-skeleton" className="grid-skeleton-container">
            <SkeletonComponent cssClass="grid-skeleton-header" width="100%" height="40px"/>
            {[...Array(rows)].map((row, rowIndex) => <div className="grid-skeleton-row" key={`row${rowIndex}`}>
                {[...Array(columns)].map((colum, columnIndex) => <SkeletonComponent cssClass="grid-skeleton-cell"
                                                                                    key={`cell${rowIndex}${columnIndex}`}/>)}
            </div>)}
        </div>
    );
}