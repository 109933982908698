import React from 'react';
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import {Link, Outlet} from "react-router-dom";
import useRouteMatch from '../../hooks/useRouteMatch';
import {
    BreadcrumbComponent,
    BreadcrumbItemDirective,
    BreadcrumbItemsDirective
} from "@syncfusion/ej2-react-navigations";
import './fleetPage.scss';

interface FleetPageProps {

};

function FleetPage(props: FleetPageProps) {
    return (
        <div className={"fleet-page-container"}>
            <div className="fleet-page-header">
                <BreadcrumbComponent enableNavigation>
                    <BreadcrumbItemsDirective>
                        <BreadcrumbItemDirective text="Dashboard" url="/"/>
                        <BreadcrumbItemDirective text="My Fleet" url="./breadcrumb/default"/>
                    </BreadcrumbItemsDirective>
                </BreadcrumbComponent>
                <FleetTabs/>
            </div>
            <Outlet/>
        </div>
    );
}

function FleetTabs() {
    const routeMatch =
        useRouteMatch([
            `/fleet/dataquality`,
            `/fleet/alerts`
        ]);
    const currentTab = routeMatch?.pattern?.path;

    return (
        <Tabs value={currentTab}>
            <Tab
                label="Alerts"
                value={`/fleet/alerts`}
                to={`/fleet/alerts`}
                component={Link}
            />
            <Tab label="Data Quality"
                 value={`/fleet/dataquality`}
                 to={`/fleet/dataquality`}
                 component={Link}
            />
        </Tabs>
    );
}

export default FleetPage;