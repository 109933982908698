import {UseQueryResult} from "react-query/types/react/types";
import {Client, DataQualityReport, VariableComplianceStatus} from "../../autogenerate/api.generated.clients";
import {useQuery} from "react-query";

export const useFleetDataQualityReport = (filter?: string): UseQueryResult<DataQualityReport[]>  =>{
    return useQuery(['fleetDataQualityReport', filter], async () => {
        const orgId = filter ? Number(filter) : null;
        return new Client().report_GetDataQualityReportsForFleet(orgId);
    });
}

