import Caution from "../../../../images/Caution.svg";
import Check from "../../../../images/Check.svg";
import Error from "../../../../images/ShutdownFault.svg";
import './variableStatusIcon.scss'

export interface VariableStatusIconProps {
    status: "good" | "missing" | "unnecessary" | "unknown";
}

export function VariableStatusIcon(props: VariableStatusIconProps) {
    const {status} = props;

    if(status === "good")
    {
        return <img src={Check} title="Variable is properly sent"
                    className="variable-status-icon variable-status-good" alt='Variable is good'/>
    }
    if(status === "missing")
    {
        return <img src={Error} title="Variable is required but missing"
                        className="variable-status-icon variable-status-missing" alt='Variable is missing'/>
    }
    if(status === "unnecessary")
    {
            return <img src={Caution} title="Variable is not needed but is being sent"
                        className="variable-status-icon variable-status-unnecessary" alt="Variable is unnecessary"/>
    }

    return <div title="Unknown" className="variable-status-icon variable-status-unknown"/>

}