import {useQuery} from "react-query";
import {Client} from "../../autogenerate/api.generated.clients";

interface UseDataQualityReportProps {
    frameSerialNumber: string;
}

function UseDataQualityReport({frameSerialNumber}: UseDataQualityReportProps) {
    return useQuery(['dataQualityReport', frameSerialNumber], async () => new Client().report_GetDataQualityReportForFrame(frameSerialNumber));
}

export default UseDataQualityReport;