import React from 'react';
import {useParams} from "react-router-dom";
import CompressorDiagram from "../../../components/CompressorDiagram/CompressorDiagram";
import './dataQualityTab.scss';
import DataQualityReportTable from "./DataQualityReport/DataQualityReportGrid";
import {Box, Button, Modal, Typography} from "@mui/material";
import useDataQualityReport from "../../../hooks/Reports/useDataQualityReport";
import RequestRevisionUpdateForm from "./RequestRevisionUpdateForm/RequestRevisionUpdateForm";
import useMlinkFirmwareRevisions from "../../../hooks/Reports/useMlinkFirmwareRevisions";
import useCurrentMlinkRevision from "../../../hooks/Reports/useCurrentMlinkRevision";

function DataQualityTab() {
    const [showUpdateForm, setShowUpdateForm] = React.useState(false);
    const params = useParams();
    const frameSerialNumber = params.frameSerialNumber!;
    const currentRevision = useCurrentMlinkRevision({frameSerialNumber})
    const dataQualityReport = useDataQualityReport({frameSerialNumber});
    
    return (
        <div className={"data-quality-tab-container"}>
            <div className={"data-quality-diagram-wrapper"}>
                <Typography variant="h6" className={"diagram-title"}>Current Configuration</Typography>
                <div className="diagram-subtitle">
                    <Typography variant="body2">Need to change the configuration or firmware revision?</Typography>
                    <Button onClick={()=> setShowUpdateForm(true)} variant="text" color="primary" size="small" sx={{textTransform: 'none', fontWeight: 'normal'}}>Request Update</Button>
                </div>
                <div className={"data-quality-diagram"}>
                    <CompressorDiagram frameSerialNumber={frameSerialNumber} isCylindersDisabled={true}
                                       isUnloadersDisabled={true}/>
                </div>
            </div>
            <div>
                <DataQualityReportTable currentRevision={currentRevision.data} data={dataQualityReport.data} isLoading={dataQualityReport.isLoading}/>
            </div>
            <Modal
                open={showUpdateForm}
                onClose={()=>setShowUpdateForm(false)}
                aria-labelledby="update-form-title"
                aria-describedby="update-form-description"
            >
                <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: 400, bgcolor: 'background.paper', border: '2px solid #000', boxShadow: 24, p: 4 }}>
                    <RequestRevisionUpdateForm frameSerialNumber={frameSerialNumber} currentRevision={currentRevision.data} onCancel={()=>setShowUpdateForm(false)} onSubmit={()=>setShowUpdateForm(false)}/>
                </Box>
            </Modal>
        </div>
    );
}

export default DataQualityTab;