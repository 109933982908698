import React, {useRef} from 'react';
import {
    ColumnDirective,
    ColumnsDirective, Edit,
    ExcelExport, Filter, FilterSettingsModel, GridComponent,
    Inject, PdfExport,
    Reorder,
    Resize,
    Sort, TextWrapSettingsModel,
    Toolbar
} from "@syncfusion/ej2-react-grids";
import {ClickEventArgs} from "@syncfusion/ej2-navigations";
import {VariableStatusIcon} from "./VariableStatusIcon";
import {Checkbox} from "@mui/material";
import {CompressorMlinkFirmwareRevisionDTO, DataQualityReport} from "../../../../autogenerate/api.generated.clients";
import {GridSkeleton} from "../../../../components/Skeletons/GridSkeleton";

interface DataQualityReportProps {
    data: DataQualityReport | undefined,
    isLoading?: boolean,
    currentRevision?: undefined | CompressorMlinkFirmwareRevisionDTO
}

function DataQualityReportGrid({data, isLoading, currentRevision}: DataQualityReportProps) {
    const grid = useRef<GridComponent>(null);
    const toolbarOptions = ['ExcelExport'];
    const filterOptions: FilterSettingsModel = {type: 'Excel'};
    const textWrapSettings: TextWrapSettingsModel = {wrapMode: "Header"}

    if (isLoading)
        return (<GridSkeleton columns={6} rows={10}/>);

    const sortedData = data?.variableComplianceStatuses.sort((a, b) => {
        if (a.isRequired && !a.isSent) return -1; // Required but not sent is first
        if (!a.isRequired && a.isSent) return (b.isRequired && !b.isSent) ? 1 : -1; // Not required but sent is second
        if (a.isRequired && a.isSent) return 1; // Good data is last
        return 0;
    });

    const dataBound = () => {
        if (grid) {
            grid.current?.autoFitColumns(['displayName', 'ascPropertyName', 'ccpAddress']);
        }
    };

    const toolbarClick = async (args: ClickEventArgs) => {
        if (grid && args.item.id === grid.current?.element.id + '_excelexport') {
            grid.current?.showSpinner();
            await grid.current?.excelExport({fileName: `${data?.frameSerialNumber}_ASC_DataQuality.xlsx`});
            grid.current?.hideSpinner();
        }
    }

    const variableStatusTemplate = (props: any) => {
        const status = props.isRequired && !props.isSent ? "missing"
            : !props.isRequired && props.isSent ? "unnecessary"
                : "good";
        return (<div className='valve-status-icon'>
            <VariableStatusIcon status={status}/>
        </div>);
    }

    const checkboxTemplate = (property: string) => (props: any) => {
        return (
            //@ts-ignore - data-testid is not a valid prop for Checkbox
            <Checkbox inputProps={{'data-testid': `${props.ascPropertyName}-${property}-checkbox`}}
                      checked={props[property]} disabled/>
        );
    }

    return (
        <div style={{display: 'flex', justifyContent: 'center', width: '100%'}}>
            <div className={`e-card`} style={{width: 'auto'}}>
                <span className="e-card-header">Data Quality Report {currentRevision ? ` using firmware revision ${currentRevision.mlinkFirmwareRevision}` : ''}</span>
                <GridComponent className="leak-index-grid"
                               width="auto" height="100%"
                               ref={grid}
                               enableStickyHeader
                               dataSource={sortedData}
                               allowResizing
                               allowSorting
                               allowFiltering
                               statelessTemplates={['directiveTemplates']}
                               allowTextWrap
                               textWrapSettings={textWrapSettings}
                               toolbar={toolbarOptions}
                               filterSettings={filterOptions} dataBound={dataBound} allowReordering
                               toolbarClick={toolbarClick} allowExcelExport
                               loadingIndicator={{indicatorType: 'Shimmer'}}>
                    <ColumnsDirective>
                        <ColumnDirective template={variableStatusTemplate} width="75px" textAlign="Left" headerText=""/>
                        <ColumnDirective field="displayName" textAlign="Left" headerText="Display Name"/>
                        <ColumnDirective field="ascPropertyName" width="auto" textAlign="Left"
                                         headerText="ASC Property"/>
                        <ColumnDirective field="ccpAddress" textAlign="Left" headerText="CCP Address"/>
                        <ColumnDirective field="isRequired" textAlign="Center" headerText="Required"
                                         template={checkboxTemplate("isRequired")}/>
                        <ColumnDirective field="isSent" textAlign="Center" headerText="Received (48h)"
                                         template={checkboxTemplate("isSent")}/>
                    </ColumnsDirective>
                    <Inject services={[Toolbar, Resize, Sort, Reorder, ExcelExport, PdfExport, Edit, Filter]}/>
                </GridComponent>
            </div>
        </div>
    );
}

export default DataQualityReportGrid;