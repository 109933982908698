import {useDrawingArea, useXScale} from "@mui/x-charts";
import React, {useState} from "react";
import {CompressorAlert, CompressorAlertSeverity} from "../../../autogenerate/api.generated.clients";
import CompressorAlertTooltip from "../../CompressorAlertTooltip/CompressorAlertTooltip";
import {styled} from "@mui/material";

export interface AlertAnnotationProps {
    alert: CompressorAlert
}
export default function AlertAnnotation(props: AlertAnnotationProps){
    const {alert} = props;
    const xAxisScale = useXScale('timestamps');
    const [isHovering, setIsHovering] = useState(false);
    const { left, top, width, height } = useDrawingArea();

    return (
        <React.Fragment>
            <StyledPath
                stroke={alert.severity === CompressorAlertSeverity.Shutdown ? 'red' : '#f9ae33'}
                d={`M ${xAxisScale(new Date(alert.faultTimeStamp))} ${45} l 0 ${height}`}
                cursor={'pointer'}
                strokeWidth={isHovering ? 2 : 1}
                opacity={isHovering ? .75 : 1}
                onMouseEnter={()=>setIsHovering(true)}
                onMouseLeave={()=>setIsHovering(false)}
            />

            <StyledContainer
                x={xAxisScale(new Date(alert.faultTimeStamp))! - 10}
                y={10}
                width={'20px'}
                height={'50px'}
                style={{pointerEvents: 'visibleFill'}}
                textAnchor="start"
                dominantBaseline="text-after-edge"
                onMouseEnter={()=>setIsHovering(true)}
                onMouseLeave={()=>setIsHovering(false)}
            >
                <CompressorAlertTooltip alert={alert} forceTooltip={isHovering}/>
            </StyledContainer>
        </React.Fragment>
    )
}


const StyledContainer = styled('foreignObject')(({ theme }) => ({
    stroke: theme.palette.text.primary,
    shapeRendering: 'crispEdges',
    strokeWidth: 1,
    clickable: true,
}));

const StyledPath = styled('path')(({ theme }) => ({
    shapeRendering: 'crispEdges',
}));