import React from 'react';
import {ISerialNumberedEquipment} from "../../../autogenerate/api.generated.clients";
import cylinderImage from "./images/Cylinder.png";
import selectedImage from "./images/CylinderSelected.png";

import './cylinder.scss'

export interface CylinderProps extends React.HTMLProps<HTMLDivElement> {
    equipment: ISerialNumberedEquipment,
    isEvenThrow?: boolean,
    isSelected?: boolean,
    equipmentSelected?: Function,
    isDisabled?: boolean
}

export function Cylinder(props: CylinderProps) {
    const {equipment, isEvenThrow, isSelected, isDisabled} = props;
    const cylinderData = equipment as any;
    const onClick = () => {
        if(isDisabled) 
            return;
        
        if (props.equipmentSelected) {
            props.equipmentSelected(equipment);
        }
    }
    return (
        <div style={props.style}
             className={`cylinder-container ${props.className} ${isEvenThrow ? 'even-throw' : 'odd-throw'} ${isDisabled ? 'disabled' : 'enabled'} ${isSelected ? 'selected' : ''}`}
             onClick={onClick}>
            <div className="cylinder-data">
                <div>{equipment.serialNumber}</div>
                <div>{cylinderData.bore}</div>
                <div>{cylinderData.model}</div>
            </div>
            <img src={isSelected ? selectedImage : cylinderImage} style={{width: '100%', height: '80%'}}/>
        </div>
    );
}