import React from 'react';
import {AfmAppBar} from "./components/AfmAppBar/AfmAppBar";
import {Navigate, Route, Routes} from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.css';
import "./styles/App.scss";
import {FleetAlerts} from "./pages/FleetPage/FleetAlerts/FleetAlerts";
import ReportsLanding from "./pages/ReportsLanding/ReportsLanding";
import AdministrationLanding from "./pages/AdministrationLanding/AdministrationLanding";
import {AddUserLanding} from "./pages/AdministrationLanding/UserManagement/AddUser/AddUserLanding";
import CompressorsPage from "./pages/CompressorsPage/CompressorsPage";
import {CompressorLandingPage} from "./pages/CompressorLanding/CompressorLandingPage";
import FaultTroubleShootingRepository from "./pages/FaultTroubleShootingRepository/FaultTroubleShootingRepository";
import CompressorAlertsTab from "./pages/CompressorLanding/CompressorAlertsTab/CompressorAlertsTab";
import ChartsTab from "./pages/CompressorLanding/ChartsTab/ChartsTab";
import CompressorConditionsSummary from "./pages/CompressorLanding/ConditionsSummary/CompressorConditionsSummary";
import ValveData from "./pages/CompressorLanding/ValveData/ValveData";
import LubeOilTab from "./pages/CompressorLanding/CompressorLubeOil/LubeOilTab";
import TelemetryDataTab from "./pages/CompressorLanding/TelemetryData/TelemetryDataTab";
import NotificationRulesTabContent from "./pages/CompressorLanding/NotificationRules/NotificationRulesTabContent";
import VibrationTab from "./pages/CompressorLanding/VibrationsTab/VibrationTab";
import {useIsVibrationsTabEnabled} from "./hooks/FeatureFlags/useIsVibrationsTabEnabled";
import {useIsDataQualityTabEnabled} from "./hooks/FeatureFlags/useIsDataQualityTabEnabled";
import DataQualityTab from "./pages/CompressorLanding/DataQuality/DataQualityTab";
import ErrorBoundary from "./components/ErrorBoundary/ErrorBoundary";
import FleetPage from "./pages/FleetPage/FleetPage";
import {useIsFleetDataQualityEnabled} from "./hooks/FeatureFlags/useIsFleetDataQualityEnabled";
import FleetDataQuality from "./pages/FleetPage/FleetDataQuality/FleetDataQuality";

function App() {
    const isVibrationsTabEnabled = useIsVibrationsTabEnabled();
    const isDataQualityTabEnabled = useIsDataQualityTabEnabled();
    const isFleetDataQualityTabEnabled = useIsFleetDataQualityEnabled();
    return (
        <div className="app-container">
            <AfmAppBar/>
            <div className="main-content">
                <Routes>
                    <Route path="" element={<Navigate to={"/compressors"}/>}/>
                    <Route path="compressors" element={<CompressorsPage/>}>
                        <Route path=":frameSerialNumber" element={<CompressorLandingPage/>}>
                            <Route path="conditions" element={<CompressorConditionsSummary />} />
                            <Route path="alerts" element={<CompressorAlertsTab />} />
                            <Route path="charts" element={<ChartsTab />} />
                            <Route path="valves" element={<ValveData />} />
                            <Route path="lube" element={<LubeOilTab />} />
                            <Route path="telemetry" element={<TelemetryDataTab />} />
                            <Route path="notifications" element={<NotificationRulesTabContent />} />
                            {isVibrationsTabEnabled ?
                                <Route path="vibration" element={<VibrationTab />} />
                                : ''
                            }
                            {isDataQualityTabEnabled ?
                                <Route path="dataquality" element={
                                    <ErrorBoundary>
                                        <DataQualityTab />
                                    </ErrorBoundary>
                                } />
                                : ''
                            }
                            <Route path="" element={<Navigate to={"conditions"}/>}/>
                        </Route>
                    </Route>
                    <Route path="/alerts" element={
                        isFleetDataQualityTabEnabled ? 
                            <Navigate to="/fleet" replace /> 
                            : <FleetAlerts/>
                    }/>
                    { isFleetDataQualityTabEnabled  && 
                        <Route path="/fleet" element={<FleetPage/>}>
                            <Route path="alerts" element={<FleetAlerts/>}/>
                            <Route path="dataquality" element={<FleetDataQuality />} />
                            <Route path="" element={<Navigate to={"alerts"}/>}/>
                        </Route>
                    }
                    <Route path="/reports" element={<ReportsLanding/>}/>
                    <Route path="/troubleshooting" element={<FaultTroubleShootingRepository />}/>
                    {/*<Route path="/notifications" element={<NotificationsLanding/>}/>*/}
                    <Route path="/administration" element={<AdministrationLanding/>}/>
                    <Route path="/administration/adduser/:organizationId" element={<AddUserLanding/>}/>
                </Routes>
            </div>
        </div>
    );
}

export default App;