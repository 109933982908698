import React, {useEffect, useState} from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import {Link, matchPath, Outlet, useLocation, useParams,} from "react-router-dom";
import './compressorLandingPage.scss';
import {CompressorInformation} from "../../components/CompressorInformation/CompressorInformation";
import {useIsVibrationsTabEnabled} from "../../hooks/FeatureFlags/useIsVibrationsTabEnabled";
import useRouteMatch from "../../hooks/useRouteMatch";
import {useIsDataQualityTabEnabled} from "../../hooks/FeatureFlags/useIsDataQualityTabEnabled";
import Badge from "@mui/material/Badge";
import useDataQualitySummary from "../../hooks/Reports/useDataQualitySummary";

interface CompressorTabsProps {
    frameSerialNumber: string
}
export function CompressorLandingPage() {
    const {frameSerialNumber} = useParams();
    if (!frameSerialNumber)
        return (<div>Compressor not found</div>)
    return (
        <div className="compressor-landing-page-container">
            <div className="compressor-landing-header">
                <CompressorInformation frameSerialNumber={frameSerialNumber}/>
                <CompressorTabs frameSerialNumber={frameSerialNumber} />
            </div>
            <div className='compressor-landing-outlet'>
                <Outlet/>
            </div>
        </div>
    );
}

function CompressorTabs(props: CompressorTabsProps) {
    const {frameSerialNumber} = props;
    const isVibrationsTabEnabled = useIsVibrationsTabEnabled();
    const isDataQualityTabEnabled = useIsDataQualityTabEnabled();
    const isDataQualityIssue = useDataQualitySummary({frameSerialNumber}).data;
    
    const routeMatch =
        useRouteMatch([
            `/compressors/${frameSerialNumber}/conditions`,
            `/compressors/${frameSerialNumber}/alerts`,
            `/compressors/${frameSerialNumber}/charts`,
            `/compressors/${frameSerialNumber}/valves`,
            `/compressors/${frameSerialNumber}/lube`,
            `/compressors/${frameSerialNumber}/telemetry`,
            `/compressors/${frameSerialNumber}/notifications`,
            `/compressors/${frameSerialNumber}/vibration`,
            `/compressors/${frameSerialNumber}/dataquality`
        ]);
    const currentTab = routeMatch?.pattern?.path;

    return (
        <Tabs value={currentTab}>
            <Tab
                label="Summary"
                value={`/compressors/${frameSerialNumber}/conditions`}
                to={`/compressors/${frameSerialNumber}/conditions`}
                component={Link}
            />
            <Tab label="Alerts"
                 value={`/compressors/${frameSerialNumber}/alerts`}
                 to={`/compressors/${frameSerialNumber}/alerts`}
                 component={Link}
            />
            <Tab
                label="Charting"
                value={`/compressors/${frameSerialNumber}/charts`}
                to={`/compressors/${frameSerialNumber}/charts`}
                component={Link}
            />
            <Tab
                label="Valves"
                value={`/compressors/${frameSerialNumber}/valves`}
                to={`/compressors/${frameSerialNumber}/valves`}
                component={Link}
            />
            <Tab
                label="Lube Oil"
                value={`/compressors/${frameSerialNumber}/lube`}
                to={`/compressors/${frameSerialNumber}/lube`}
                component={Link}
            />
            <Tab
                label="Telemetry"
                value={`/compressors/${frameSerialNumber}/telemetry`}
                to={`/compressors/${frameSerialNumber}/telemetry`}
                component={Link}
            />
            <Tab
                label="Notifications"
                value={`/compressors/${frameSerialNumber}/notifications`}
                to={`/compressors/${frameSerialNumber}/notifications`}
                component={Link}
            />
            {isVibrationsTabEnabled ?
                <Tab
                    label="Vibration"
                    value={`/compressors/${frameSerialNumber}/vibration`}
                    to={`/compressors/${frameSerialNumber}/vibration`}
                    component={Link}
                />
                :''
            }
            {isDataQualityTabEnabled ?
                <Badge badgeContent={isDataQualityIssue ? 1 : 0} variant="dot" overlap="circular" color="error">
                    <Tab
                        label="Data Quality"
                        value={`/compressors/${frameSerialNumber}/dataquality`}
                        to={`/compressors/${frameSerialNumber}/dataquality`}
                        component={Link}
                    />
                </Badge>
                :''
            }
        </Tabs>
    );
}


