import React, {useEffect, useState} from 'react';
import {CompressorDiagram} from "../../../components/CompressorDiagram/CompressorDiagram";
import {ISerialNumberedEquipment, SerialNumberedEquipmentType} from "../../../autogenerate/api.generated.clients";
import {CylinderTelemetryDisplay} from "./CylinderTelemetryDisplay/CylinderTelemetryDisplay";
import './telemetryDataTab.scss'
import {ThrowRodLoadTelemetry} from "./CylinderTelemetryDisplay/ThrowRodLoadTelemetry";
import {ThrowStageMapping} from "./FrameTelemetry/ThrowStageMapping";
import {CylinderPosition} from "../../../enums";
import {CylinderEndTelemetry} from "./CylinderTelemetryDisplay/CylinderEndTelemetry";
import {useParams} from "react-router-dom";
import ReactGA from "react-ga4";


export default function TelemetryDataTab() {
    const params = useParams();
    const frameSerialNumber = params.frameSerialNumber;
    const [selectedEquipment, setSelectedEquipment] = useState<ISerialNumberedEquipment>();

    useEffect(() => {
        ReactGA.send({ hitType: "pageview", page: `/compressors/${frameSerialNumber}/telemetry`, title: "Compressor Telemetry" });
    }, []);
    
    const onEquipmentChanged = (equipment: ISerialNumberedEquipment) => {
        setSelectedEquipment(equipment);
    }
    return (
        <div className="telemetry-data-container">
            <div className="compressor-diagram">
                <div className="diagram-instructions">Click the diagram to change your selection</div>
                <CompressorDiagram isUnloadersDisabled={true} selectFrameOnLoad frameSerialNumber={frameSerialNumber!}
                                   equipmentChanged={onEquipmentChanged}/>
            </div>
            {selectedEquipment?.equipmentType == SerialNumberedEquipmentType.Cylinder ?
                <div className="throw-telemetry-data-container">
                    <CylinderTelemetryDisplay cylinderSN={selectedEquipment.serialNumber}
                                              isPositionHE={(selectedEquipment as any).position === CylinderPosition.HE} 
                                              frameSerialNumber={frameSerialNumber!}/>
                    <CylinderEndTelemetry frameSerialNumber={frameSerialNumber!}
                                          throwNumber={(selectedEquipment as any).throwNumber}
                                          cylinderSerialNumber={selectedEquipment.serialNumber}/>
                    <ThrowRodLoadTelemetry frameSerialNumber={frameSerialNumber!}
                                           throwNumber={(selectedEquipment as any).throwNumber}/>
                    <ThrowStageMapping frameSerialNumber={frameSerialNumber!}/>
                </div>
                : ""
            }
        </div>
    );
}